import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import SectionButton from "./SectionButton";
import "./HeroSection.scss";

function CPAServices(props) {
    return (
        <Section id="smm-services" className="vc_row pt-70 pb-70 bg-no-repeat">
            <div className="container">
                <div class="row">
                    <div class="lqd-column col-md-10 col-md-offset-1">
                        <header class="fancy-title text-center mb-75" data-custom-animations="true" data-ca-options='{"triggerHandler":"inview","animationTarget":"all-childs","duration":1200,"delay":100,"initValues":{"translateY":80,"opacity":0},"animations":{"translateY":0,"opacity":1}}'>
                            <h6 class="text-uppercase ltr-sp-2 text-secondary">What We Provide</h6>
                            <h2 class="mt-4 mb-4">Our <strong>Services </strong></h2>
                            <div class="row">
                                <div class="lqd-column col-md-6 col-md-offset-3 px-md-5">
                                    <p class="font-size-18 lh-15">Custom Web Design and Development Features for Accountants</p>
                                </div>
                            </div>
                        </header>
                    </div>
                </div>
                <div class="row" data-custom-animations="true" data-ca-options='{"triggerHandler":"inview","animationTarget":"all-childs","duration":"1200","delay":"180","initValues":{"scale":0.8,"opacity":0},"animations":{"scale":1,"opacity":1}}'>
                    
                    <div class="col-md-4">
                        <div className="iconbox text-left iconbox-round iconbox-lg iconbox-filled iconbox-filled iconbox-icon-image iconbox-shadow pt-50 pb-40">
                            <div className="iconbox-icon-wrap">
                                <span className="iconbox-icon-container mb-35">
                                    
                                    <img src={require('../assets/demo/icons/icon-1.png')}/>
                                </span>
                            </div>
                            <div className="contents">
                                <h3><a href="tel:+16043051535">Creative Website Design</a></h3>
                                <p className="font-size-16 lh-165">Startup ipsum dolor sit amet consuring elised faisism dolor sit amet.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="iconbox text-left iconbox-round iconbox-lg iconbox-filled iconbox-filled iconbox-icon-image iconbox-shadow pt-50 pb-40">
                            <div className="iconbox-icon-wrap">
                                <span className="iconbox-icon-container mb-35">
                                    
                                <img src={require('../assets/demo/icons/icon-2.png')}/>
                                </span>
                            </div>
                            <div className="contents">
                                <h3><a href="tel:+16043051535">Responsive Design</a></h3>
                                <p className="font-size-16 lh-165">Leader ipsum dolor sit amet consuring lised faisism dolor sit amet.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="iconbox text-left iconbox-round iconbox-lg iconbox-filled iconbox-filled iconbox-icon-image iconbox-shadow pt-50 pb-40">
                            <div className="iconbox-icon-wrap">
                                <span className="iconbox-icon-container mb-35">
                                    
                                <img src={require('../assets/demo/icons/icon-3.png')}/>
                                </span>
                            </div>
                            <div className="contents">
                                <h3><a href="tel:+16043051535">Content Writing</a></h3>
                                <p className="font-size-16 lh-165">Leader ipsum dolor sit amet consuring lised faisism dolor sit amet.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="iconbox text-left iconbox-round iconbox-lg iconbox-filled iconbox-filled iconbox-icon-image iconbox-shadow pt-50 pb-40">
                            <div className="iconbox-icon-wrap">
                                <span className="iconbox-icon-container mb-35">
                                    
                                <img src={require('../assets/demo/icons/icon-4.png')}/>
                                </span>
                            </div>
                            <div className="contents">
                                <h3><a href="tel:+16043051535">SEO-Friendly Design</a></h3>
                                <p className="font-size-16 lh-165">Leader ipsum dolor sit amet consuring lised faisism dolor sit amet.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="iconbox text-left iconbox-round iconbox-lg iconbox-filled iconbox-filled iconbox-icon-image iconbox-shadow pt-50 pb-40">
                            <div className="iconbox-icon-wrap">
                                <span className="iconbox-icon-container mb-35">
                                    
                                <img src={require('../assets/demo/icons/icon-5.png')}/>
                                </span>
                            </div>
                            <div className="contents">
                                <h3><a href="tel:+16043051535">Appointment Scheduling</a></h3>
                                <p className="font-size-16 lh-165">Leader ipsum dolor sit amet consuring lised faisism dolor sit amet.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="iconbox text-left iconbox-round iconbox-lg iconbox-filled iconbox-filled iconbox-icon-image iconbox-shadow pt-50 pb-40">
                            <div className="iconbox-icon-wrap">
                                <span className="iconbox-icon-container mb-35">
                                    
                                <img src={require('../assets/demo/icons/icon-6.png')}/>
                                </span>
                            </div>
                            <div className="contents">
                                <h3><a href="tel:+16043051535">Website Maintenance</a></h3>
                                <p className="font-size-16 lh-165">Leader ipsum dolor sit amet consuring lised faisism dolor sit amet.</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </Section>
    );
}

export default CPAServices;
