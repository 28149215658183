import React from "react";
import CPAHeroSection from "../components/CPAHeroSection";
import CPAAbout from "../components/CPAAbout";
import CPAServices from "../components/CPAServices";
import CPANumbers from "../components/CPANumbers";
import OurPortfolio from "./../components/OurPortfolio";
import CPATestimonials from "../components/CPATestimonials";
import CPACTA from "../components/CPACTA";
import ClientsSection from "../components/ClientsSection";
import FeaturesSection from "../components/FeaturesSection";
import TestimonialsSection from "../components/TestimonialsSection";
import NewsletterSection from "../components/NewsletterSection";
import { useRouter } from "../util/router.js";

// import "../assets/css/themes/digital-agency.css";



function CPAPage(props) {
  const router = useRouter();
  // const IndexLayout = ({location }) => {
  //   if (location.pathname === withPrefix ("/test")){
  //       require("../assets/css/themes/digital-agency.css")
  //   }
  //       else{
  //           console.log("fail")
  //       }
  
  // };
  return (
    <>
      <CPAHeroSection
       
      />
      <CPAAbout />
      <CPAServices />
      <OurPortfolio />
      <CPANumbers />
      <CPATestimonials />
      <CPACTA />
      
    </>
  );
  
}
document.body.classList.add('cpa-page');
export default CPAPage;
