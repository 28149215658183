import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import SectionButton from "./SectionButton";
import "./HeroSection.scss";

function CPATestimonials(props) {
    return (
        <Section id="" className="vc_row pt-150 pb-100">
                <div class="container">
                    <div class="row">
                        <div class="lqd-column col-lg-4 col-md-12" data-custom-animations="true" data-ca-options='{"triggerHandler":"inview","animationTarget":"all-childs","duration":1200,"delay":160,"initValues":{"translateY":50,"opacity":0},"animations":{"translateY":0,"opacity":1}}'>
                            <header class="fancy-title mb-130">
                                <h6 class="text-uppercase ltr-sp-2 font-size-12 font-weight-semibold text-secondary">TESTIMONIALS</h6>
                                <h2 class="mt-4 mb-4">
                                    <strong>Client</strong> Stories</h2>
                                <p>We’re humbled to be working with such a great variety of clients that range from early stage startups to Fortune companies.</p>
                            </header>
                        </div>
                        <div class="lqd-column col-lg-7 col-lg-offset-1 col-xs-12 mb-7 mb-md-0">
                            <div class="carousel-container carousel-vertical-3d">
                                <div class="carousel-items">
                                    <div class="carousel-item is-active">
                                        <div class="testimonial testimonial-whole-filled testimonial-whole-shadowed text-left testimonial-details-sm testimonial-avatar-sm">
                                            <div class="testimonial-quote">
                                                <blockquote>
                                                    <p class="font-size-16 lh-185">“Embtel Solutions is one of the best IT company in Surat for website development and digital marketing services provider.”</p>
                                                </blockquote>
                                            </div>
                                            <div class="testimonial-details">
                                                <figure class="avatar ">
                                                    <img src={require('../assets/demo/testimonials/testi-3.png')} alt="Embtel Tran" />
                                                </figure>
                                                    <div class="testimonial-info">
                                                        <h5>Tabil Dew</h5>
                                                        <h6 class="font-weight-normal">Toronto</h6>
                                                    </div>
                                                    <time datetime="2017-07-07" class="text-uppercase">Now</time>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <div class="testimonial testimonial-whole-filled testimonial-whole-shadowed text-left testimonial-details-sm testimonial-avatar-sm">
                                                <div class="testimonial-quote">
                                                    <blockquote>
                                                        <p class="font-size-16 lh-185">“24*7 customer support. They gave us the best service after the website. Best website design company in Canada.”</p>
                                                    </blockquote>
                                                </div>
                                                <div class="testimonial-details">
                                                    <figure class="avatar ">
                                                        <img src={require('../assets/demo/testimonials/testi-4.png')} alt="Embtel Tran" />
                                                    </figure>
                                                        <div class="testimonial-info">
                                                            <h5>Suke Tran</h5>
                                                            <h6 class="font-weight-normal">Vancouver</h6>
                                                        </div>
                                                        <time datetime="2017-07-07" class="text-uppercase">Now</time>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="carousel-item">
                                                <div class="testimonial testimonial-whole-filled testimonial-whole-shadowed text-left testimonial-details-sm testimonial-avatar-sm">
                                                    <div class="testimonial-quote">
                                                        <blockquote>
                                                            <p class="font-size-16 lh-185">“Embtel Solutions have great professional team of developers and they understand and deliver exactly what client wants.”</p>
                                                        </blockquote>
                                                    </div>
                                                    <div class="testimonial-details">
                                                        <figure class="avatar">
                                                            <img src={require('../assets/demo/testimonials/testi-5.png')} alt="Embtel Tran" />
                                                        </figure>
                                                            <div class="testimonial-info">
                                                                <h5>Veronica L.</h5>
                                                                <h6 class="font-weight-normal">Calgary</h6>
                                                            </div>
                                                            <time datetime="2017-07-07" class="text-uppercase">Now</time>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
        </Section>
    );
}

export default CPATestimonials;
