import React from "react";

function NavbarContainer(props) {
  return (
    <header class="main-header main-header-overlay">
      <div class="mainbar-wrap">
        <div class="megamenu-hover-bg"></div>
        <div class="container-fluid mainbar-container">
          <div class="mainbar">
            <div class="row mainbar-row align-items-lg-stretch px-4">
                <div class="col-auto">
                    <div class="navbar-header">
                        <a class="navbar-brand" href="/" rel="home">
                            <span class="navbar-brand-inner">
                                <img class="logo-dark" src={require('../assets/img/ca-logo.png')}
                                    alt="Embtel Solutions" />
                                <img class="logo-sticky" src={require('../assets/img/ca-logo.png')}
                                    alt="Embtel Solutions" />
                                <img class="mobile-logo-default" src={require('../assets/img/ca-logo.png')}
                                    alt="Embtel Solutions" />
                                <img class="logo-default" src={require('../assets/img/ca-logo.png')}
                                    alt="Embtel Solutions" />
                            </span>
                        </a>
                        <button type="button" class="navbar-toggle collapsed nav-trigger style-mobile"
                            data-toggle="collapse" data-target="#main-header-collapse" aria-expanded="false"
                            data-changeclassnames='{ "html": "mobile-nav-activated overflow-hidden" }'>
                            <span class="sr-only">Toggle navigation</span>
                            <span class="bars">
                                <span class="bar"></span>
                                <span class="bar"></span>
                                <span class="bar"></span>
                            </span>
                        </button>
                    </div>
                </div>
                <div class="col">
                  <div class="collapse navbar-collapse" id="main-header-collapse">
                    {props.children}
                  </div>
                </div>
                <div class="col-auto text-right">
                    <div class="header-module">
                        <p>CALL US NOW: <span class="header-call-btn"><a href="tel:+15105856585"> +1 (604) 305 1535</a></span>
                        </p>
                    </div>
                    <div class="header-module">
                        <a href="#contact-us"
                            class="btn btn-default text-uppercase btn-sm circle btn-bordered border-thin px-2">
                            <span>
                                <span class="btn-txt">Get A Quote</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    
  );
}

export default NavbarContainer;
