import React from "react";
import PrivacyPolicy from "../components/PrivacyPolicy";

import { useRouter } from "../util/router.js";

function PrivacyPolicyPage(props) {
  const router = useRouter();

  return (
    <>
      <PrivacyPolicy
        
      />
      
      
    </>
  );
}

export default PrivacyPolicyPage;
