import React, {useState} from "react";
import Section from "./Section";
import CPAContactForm from "./CPAContactForm";
import cpacontact from "./../util/cpa-contact.js";
import SectionHeader from "./SectionHeader";
import SectionButton from "./SectionButton";
import "./HeroSection.scss";

function CPAHeroSection(props) {
    const [status, setStatus] = useState();

  const onSubmit = ({ name, email, message, phone }) => {
    setStatus({ type: "pending" });

    cpacontact.submit({ name, email, message, phone }).then(() => {
      setStatus({
        type: "success",
        message: "Your message has been sent! We'll get back to you soon."
      });
    });
  };
    return (
        <Section id="cpa-hero" className="vc_row fullheight d-flex flex-wrap align-items-center mb-80">
            {/* <span class="row-bg-loader">
                    <span class="row-bg-loader-inner"></span>
                </span> */}
            <div class="container">
                <div class="row d-flex align-items-center justify-content-between">
                    <div class=" col-md-6">
                        <header class="fancy-title">
                            <h6 class="text-uppercase font-weight-normal ltr-sp-2 text-white mb-3">EMBTEL SOLUTIONS</h6>
                            <h2 class="text-white mb-4"><span class="font-size-4-3x lh-1"><strong>Web Design for Accounting Firms and CPAs</strong></span></h2>
                            <div class="row">
                                <div class="lqd-column col-md-10">
                                    <p class="text-white font-size-20 mb-1">We specialize in designing innovative, responsive websites with a focus on lead generation and conversion.</p>
                                </div>
                            </div>
                        </header>
                        <a href="tel:+16043051535" class="btn btn-solid text-uppercase circle btn-bordered border-thin font-size-16 font-weight-bold ltr-sp-05 px-2 bg-hover-white text-hover-black mb-2" data-localscroll="true" data-localscroll-options='{"scrollBelowSection":true}'>
                            <span>
                                <span class="btn-txt">Call Us Now</span>
                            </span>
                        </a>
                        {/* <a href="https://www.youtube.com/watch?v=LjaKHqDbzSA" class="btn btn-default text-uppercase circle btn-bordered border-thick fresco btn-icon-left btn-icon-circle btn-icon-bordered font-size-16 font-weight-bold ltr-sp-05 px-2 color-secondary mb-2">
                                <span>
                                    <span class="btn-txt">How we work</span>
                                    <span class="btn-icon"><i class="fa fa-play"></i></span>
                                </span>
                            </a> */}
                    </div>
                    <div class=" col-md-5 col-xs-12 px-md-4 text-center">
                        <div class="lqd-column-inner bg-white border-radius-6 px-3 px-md-4 pt-40 pb-40">
                            <header class="fancy-title">
                                <h2 class="mb-2 font-size-30 font-weight-bold">Free Consultation</h2>
                                <p class="mt-0">All consultants are able to give you a free call back at any time.</p>
                            </header>
                            <div class="contact-form contact-form-inputs-filled contact-form-button-block font-size-14">
                                {/* <form action="http://avehtml.liquid-themes.com/assets/php/mailer.php" method="post" novalidate="novalidate">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <input class="bg-gray text-dark" type="text" name="name" aria-required="true" aria-invalid="false" placeholder="First Name" required />
                                        </div>
                                        <div class="col-md-12">
                                            <input class="bg-gray text-dark" type="email" name="email" aria-required="true" aria-invalid="false" placeholder="Your email address" required />
                                        </div>
                                        <div class="col-md-12">
                                            <input class="bg-gray text-dark" type="tel" name="mobile" aria-required="true" aria-invalid="false" placeholder="Mobile no" required />
                                        </div>
                                        <div class="col-md-12 text-md-right">
                                            <input type="submit" value="Get Free Consultation" />
                                        </div>
                                    </div>
                                </form> */}
                                <CPAContactForm
                                        parentColor={props.parentColor}
                                        showNameField={props.showNameField}
                                        buttonText="Send Message"
                                        onSubmit={onSubmit}
                                        status={status}
                                    />
                                <div class="contact-form-result hidden"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="my-custom-bg col-xs-12 px-0">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 border-right">
                            <div class="featured-service px-4 py-4">
                                <h4 class="mt-0">Website Design</h4>
                                <p class="mb-0">Specializing in creating highly performing sites for mid-market to enterprise businesses.</p>
                                <a href="#" class="line-btns">Request a Quote</a>
                            </div>
                        </div>
                        <div class="col-md-4 border-right">
                            <div class="featured-service px-4 py-4">
                                <h4 class="mt-0">Digital Marketing</h4>
                                <p class="mb-0">Prospects are people too. Marketing should be scientific, but never robotic.</p>
                                <a href="#" class="line-btns">Request a Quote</a>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="featured-service px-4 py-4">
                                <h4 class="mt-0">Website Development</h4>
                                <p class="mb-0">Step inside the center of excellence where we build bold, engaging new websites. </p>
                                <a href="#" class="line-btns">Request a Quote</a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div> */}
      
    </Section>
  );
}

export default CPAHeroSection;
