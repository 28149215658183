import React from "react";
import HeroSection from "./../components/HeroSection";
import OurFeatures from "./../components/OurFeatures";
import OurServices from "./../components/OurServices";
import OurPortfolio from "./../components/OurPortfolio";
import OurTestimonials from "./../components/OurTestimonials";
import ContactUs from "./../components/ContactUs";
import ClientsSection from "./../components/ClientsSection";
import FeaturesSection from "./../components/FeaturesSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import NewsletterSection from "./../components/NewsletterSection";
import { useRouter } from "./../util/router.js";

function IndexPage(props) {
  const router = useRouter();
  document.body.classList.remove('cpa-page');
  return (
    <>
      <HeroSection
        color="white"
        size="medium"
        title="Your landing page title here"
        subtitle="This landing page is perfect for showing off your awesome product and driving people to sign up for a paid plan."
        buttonText="Get Started"
        image="https://uploads.divjoy.com/undraw-japan_ubgk.svg"
        buttonOnClick={() => {
          router.push("/pricing");
        }}
      />
      <OurFeatures />
      <OurServices />
      <OurPortfolio />
      <OurTestimonials />
      <ContactUs />
      
    </>
  );
}

export default IndexPage;
