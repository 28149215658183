import React, { useState } from "react";
import Section from "./Section";
import ContactForm from "./ContactForm";
import contact from "./../util/contact.js";
import SectionHeader from "./SectionHeader";
import SectionButton from "./SectionButton";
import ReCAPTCHA from "react-google-recaptcha";

import "./HeroSection.scss";

function ContactUs(props) {
    const [status, setStatus] = useState();

  const onSubmit = ({ name, email, message, phone }) => {
    setStatus({ type: "pending" });

    contact.submit({ name, email, message, phone }).then(() => {
      setStatus({
        type: "success",
        message: "Your message has been sent! We'll get back to you soon."
      });
    });
  };
    return (
        <Section id="contact-us" className="vc_row">
            <div class="container">
                <div class="row">
                    <div class="lqd-column col-md-12 box-shadow-1 pull-up">
                        <div class="row">
                            <div class="lqd-column col-md-6 px-5 py-5">
                                <header class="fancy-title">
                                    <h2><strong>Drop us a line</strong></h2>
                                    <p class="font-size-18 lh-15">We are here to answer any question you may have</p>
                                </header>
                                <div class="contact-form contact-form-inputs-underlined contact-form-button-circle">
                                    {/* <form action="https://formspree.io/mdolrqww" method="post" novalidate="novalidate">
                                        <input class="lh-25 mb-30" type="text" name="name" aria-required="true" aria-invalid="false" placeholder="First Name" required />
                                        <input class="lh-25 mb-30" type="email" name="email" aria-required="true" aria-invalid="false" placeholder="Your email address" required />
                                        <input class="lh-25 mb-30" type="tel" name="mobile" aria-required="true" aria-invalid="false" placeholder="Mobile no" required />
                                        <textarea class="mb-30" cols="10" rows="2" name="message" aria-required="true" aria-invalid="false" placeholder="Message" required></textarea>
                                        <ReCAPTCHA
                                            sitekey="6LejmO0UAAAAAPPejFSKn6y1vX4l0DsNRUl3HdNb"
                                            size="invisible"
                                        />
                                        <input type="submit" value="Send message" class="font-size-13 ltr-sp-1 font-weight-bold" />
                                    </form> */}
                                    <ContactForm
                                        parentColor={props.parentColor}
                                        showNameField={props.showNameField}
                                        buttonText="Send Message"
                                        onSubmit={onSubmit}
                                        status={status}
                                    />
                                    {/* <div class="contact-form-result hidden"></div> */}
                                </div>
                            </div>
                            <div class="lqd-column col-md-6 px-5 py-5 bg-custom"> 
                                <div className="book-now" style={{height:'100%'}}>
                                    <header class="fancy-title">
                                        <h2><strong>Free Consultation</strong></h2>
                                        <p class="font-size-18 lh-15">Ready to speak with a marketing expert? Share your details and we will get back to you as soon as possible to schedule a meeting.</p>
                                    </header>
                                    <div className="phone-link">
                                        <h3><i class="la la-phone"></i>+1 (604) 305 1535</h3>
                                    </div>
                                    <div className="certificates">
                                        <img src={require('./../assets/img/banner-certificate.png')} />
                                    </div>
                                    <div class="features-list">
                                        <div class="single-feature">
                                            <i class="la la-check"></i>
                                            <h5>Leading Digital Marketing Agency</h5>
                                        </div>
                                        <div class="single-feature">
                                            <i class="la la-check"></i>
                                            <h5>200+ Websites Launched</h5>
                                        </div>
                                        <div class="single-feature">
                                            <i class="la la-check"></i>
                                            <h5>Client Revenue Increased</h5>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="iconbox text-right iconbox-xl" data-animate-icon="true" data-plugin-options='{"resetOnHover":true,"color":"rgb(216, 219, 226)","hoverColor":"rgb(0, 0, 0)"}'>
                                    <div class="iconbox-icon-wrap">
                                        <span class="iconbox-icon-container">
                                            <i style={{ color: '#dadde3' }} class="lab la-telegram-plane"></i>
                                        </span>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        {/* <div class="row">
                            <div class="lqd-column col-md-10 col-md-offset-1">
                                <div class="contact-form contact-form-inputs-underlined contact-form-button-circle">
                                    <form action="https://formspree.io/mdolrqww" method="post" novalidate="novalidate">
                                        <div class="row d-flex flex-wrap">
                                            <div class="lqd-column col-md-6 mb-20">
                                                <input class="lh-25 mb-30" type="text" name="name" aria-required="true" aria-invalid="false" placeholder="First Name" required />
                                                <input class="lh-25 mb-30" type="email" name="email" aria-required="true" aria-invalid="false" placeholder="Your email address" required />
                                                <input class="lh-25 mb-30" type="tel" name="mobile" aria-required="true" aria-invalid="false" placeholder="Mobile no" required />
                                                <textarea cols="10" rows="6" name="message" aria-required="true" aria-invalid="false" placeholder="Message" required></textarea>
                                                <input type="submit" value="Send message" class="font-size-13 ltr-sp-1 font-weight-bold" />
                                            </div>
                                            
                                        </div>
                                    </form>
                                    <div class="contact-form-result hidden"></div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div class="row pt-90 pb-60">
                    <div class="lqd-column col-md-12">
                        <div class="row">
                            <div class="lqd-column col-md-4 px-3">
                                <div class="single-address px-5 py-3 bg-white box-shadow-1">
                                    <h2 class="font-size-24 font-weight-bold"><small><i class="icon-liquid_map_pin mr-2 fa-1x"></i></small> USA</h2>
                                    <p class="font-size-16">39159 Paseo Padre Pkwy, Suite 119, Fremont, CA 94538</p>
                                    <hr class="w-30 ml-0 border-color-primary" />
                                    <p class="font-size-16"><a href="tel:+15106519615">+1 (510) 651-9615</a> <br/> <a href="mailto:hello@embtelsolutions.com" class="__cf_email__" >hello@embtelsolutions.com</a></p>
                                </div>
                            </div>
                            <div class="lqd-column col-md-4 px-3">
                                <div class="single-address px-5 py-3 bg-white box-shadow-1">
                                    <h2 class="font-size-24 font-weight-bold"><small><i class="icon-liquid_map_pin mr-2 fa-1x"></i></small> India</h2>
                                    <p class="font-size-16">501, SNS Business Park, Vesu, Surat, Gujarat 395007</p>
                                    <hr class="w-30 ml-0 border-color-primary" />
                                    <p class="font-size-16"><a href="tel:+1 510 585 6585">+1 (510) 585 6585</a> <br/> <a href="mailto:hello@embtelsolutions.in" class="__cf_email__" >hello@embtelsolutions.in</a></p>
                                </div>
                            </div>
                            <div class="lqd-column col-md-4 px-3">
                                <div class="single-address px-5 py-3 bg-white box-shadow-1">
                                    <h2 class="font-size-24 font-weight-bold"><small><i class="icon-liquid_map_pin mr-2 fa-1x"></i></small> Canada</h2>
                                    <p class="font-size-16">#209A - 12815 85 Ave, Surrey, British Columbia V3W 0K8, Canada</p>
                                    <hr class="w-30 ml-0 border-color-primary" />
                                    <p class="font-size-16"><a href="tel:+16043051535">+1 (604) 305 1535</a> <br /> <a href="mailto:ravinder@embtelsolutions.ca" class="__cf_email__">ravinder@embtelsolutions.ca</a></p>
                                </div>
                            </div>
                            {/* <div class="lqd-column col-md-3 col-sm-8 px-5">
                                <h2 class="font-size-24 font-weight-bold"><small><i class="icon-liquid_map_pin mr-2 fa-1x"></i></small> Netherland</h2>
                                <p class="font-size-16">Netherland</p>
                                <hr class="w-30 ml-0 border-color-primary" />
                                <p class="font-size-16"><a href="tel:0000000000">000 000 0000</a> <br /> <a href="preeti@embtelsolutions.nl" class="__cf_email__">preeti@embtelsolutions.nl</a></p>
                            </div>
                            <div class="lqd-column col-md-3 col-sm-8 px-5">
                                <h2 class="font-size-24 font-weight-bold"><small><i class="icon-liquid_map_pin mr-2 fa-1x"></i></small> Germany</h2>
                                <p class="font-size-16">Dortmund, Germany</p>
                                <hr class="w-30 ml-0 border-color-primary" />
                                <p class="font-size-16"><a href="tel:+49 2363 9099 921">+47 213 5941 295</a> <br /> <a href="lars@embtelsolutions.de" class="__cf_email__">lars@embtelsolutions.de</a></p>
                            </div> */}
                        </div>
                    </div>
                </div>
                
            </div>
        
    </Section>
  );
}

export default ContactUs;
