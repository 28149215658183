import React, { useState } from "react";
import FormStatus from "./FormStatus";
import FormField from "./FormField";
import SectionButton from "./SectionButton";

import ReCAPTCHA from "react-google-recaptcha";

function CPAContactForm(props) {
  // State for input values
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");

  // Whether to show errors
  // We set to true if they submit and there are errors.
  // We only show errors after they submit because
  // it's annoying to see errors while typing.
  const [showErrors, setShowErrors] = useState(false);

  // Error array we'll populate
  let errors = [];

  // Function for fetching error for a field
  const getError = field => {
    return errors.find(e => e.field === field);
  };

  // Function to see if field is empty
  const isEmpty = val => val.trim() === "";

  // Add error if email empty
  if (isEmpty(email)) {
    errors.push({
      field: "email",
      message: "Please enter an email"
    });
  }

  if (isEmpty(phone)) {
    errors.push({
      field: "phone",
      message: "Please enter an phone"
    });
  }

  // Add error if message empty
  if (isEmpty(message)) {
    errors.push({
      field: "message",
      message: "Please enter a message"
    });
  }

  // Add error if name shown and empty
  if (props.showNameField) {
    if (isEmpty(name)) {
      errors.push({
        field: "name",
        message: "Please enter your name"
      });
    }
  }

  // Handle form submission
  const handleSubmit = e => {
    // If field errors then show them
    if (errors.length) {
      setShowErrors(true);
    } else {
      // Otherwise call onSubmit with form data
      if (props.onSubmit) {
        props.onSubmit({
          name,
          email,
          message, 
          phone
        });
      }
    }
  };

  return (
    <>
      {props.status && props.status.message && (
        <FormStatus type={props.status.type} message={props.status.message} />
      )}

      <form
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
          <FormField
                value={name}
                classes="lh-25 mb-30"
                type="text"
                placeholder="Name"
                error={showErrors && getError("name")}
                onChange={value => setName(value)}
              />
            

            <FormField
              value={email}
              classes="lh-25 mb-30"
              type="email"
              placeholder="Email Address"
              error={showErrors && getError("email")}
              onChange={value => setEmail(value)}
            />

            <FormField
              value={phone}
              classes="lh-25 mb-30"
              type="text"
              placeholder="Phone Number"
              error={showErrors && getError("phone")}
              onChange={value => setPhone(value)}
            />
          
        
            <FormField
              value={message}
              type="textarea"
              placeholder="Message"
              error={showErrors && getError("message")}
              onChange={value => setMessage(value)}
            />

              <ReCAPTCHA
                  sitekey="6LejmO0UAAAAAPPejFSKn6y1vX4l0DsNRUl3HdNb"
                  size="invisible"
              />
        
                <SectionButton
                  parentColor={props.parentColor}
                  size="medium"
                  state={
                    props.status && props.status.type === "pending"
                      ? "loading"
                      : "normal"
                  }
                >
                  {props.buttonText}
                </SectionButton>
      </form>
    </>
  );
}

export default CPAContactForm;
