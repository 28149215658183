import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import SectionButton from "./SectionButton";
import "./HeroSection.scss";

function CPANumbers(props) {
    return (
        <Section id="cpa-numbers" className="vc_row pt-115 pb-85 mt-50 mb-50 bg-cover">
            
                <div class="liquid-row-overlay"></div>
                <div class="container">
                    <div class="row">
                        <div class="lqd-column col-md-3 col-sm-6 text-center">
                            <div class="iconbox iconbox-xl mb-4" data-plugin-options='{"color": "#fff"}'>
                                <div class="iconbox-icon-wrap">
                                    <span class="iconbox-icon-container mb-0 text-white">
                                        <i style={{color:'#FD7250'}} class="la la-check-circle"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="liquid-counter liquid-counter-default text-white text-center  px-5">
                                <div class="liquid-counter-element mb-2" data-fittext="true" data-fittext-options='{ "compressor": 0.5, "minFontSize": 55, "maxFontSize": 55 }' data-enable-counter="true" data-counter-options='{"targetNumber":"700+","blurEffect":true}'>
                                    <span>500+</span>
                                </div>
                                <span class="liquid-counter-text liquid-text-bottom opacity-06"><strong>Successfully Completed Cases</strong></span>
                            </div>
                        </div>
                        <div class="lqd-column col-md-3 col-sm-6 text-center">
                            <div class="iconbox iconbox-xl mb-4 " data-plugin-options='{"color": "#fff"}'>
                                <div class="iconbox-icon-wrap">
                                    <span class="iconbox-icon-container mb-0 text-white">
                                        <i style={{color:'#FD7250'}} class="la la-users"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="liquid-counter liquid-counter-default text-white text-center px-5">
                                <div class="liquid-counter-element mb-2" data-fittext="true" data-fittext-options='{ "compressor": 0.5, "minFontSize": 55, "maxFontSize": 55 }' data-enable-counter="true" data-counter-options='{"targetNumber":"125+","blurEffect":true}'>
                                    <span>750+</span>
                                </div>
                                <span class="liquid-counter-text liquid-text-bottom"><strong>Highly Specialized Consultants</strong></span>
                            </div>
                        </div>
                        <div class="lqd-column col-md-3 col-sm-6 text-center">
                            <div class="iconbox iconbox-xl mb-4 " data-plugin-options='{"color": "#fff"}'>
                                <div class="iconbox-icon-wrap">
                                    <span class="iconbox-icon-container mb-0 text-white">
                                        <i style={{color:'#FD7250'}} class="icon-ion-ios-happy"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="liquid-counter liquid-counter-default text-white text-center  px-5">
                                <div class="liquid-counter-element mb-2" data-fittext="true" data-fittext-options='{ "compressor": 0.5, "minFontSize": 55, "maxFontSize": 55 }' data-enable-counter="true" data-counter-options='{"targetNumber":"682+","blurEffect":true}'>
                                    <span>100%</span>
                                </div>
                                <span class="liquid-counter-text liquid-text-bottom"><strong>Satisfaction Of Our Customers</strong></span>
                            </div>
                        </div>
                        <div class="lqd-column col-md-3 col-sm-6 text-center">
                            <div class="iconbox iconbox-xl mb-4 " data-plugin-options='{"color": "#fff"}'>
                                <div class="iconbox-icon-wrap">
                                    <span class="iconbox-icon-container mb-0 text-white">
                                        <i style={{color:'#FD7250'}} class="icon-ion-ios-globe"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="liquid-counter liquid-counter-default text-white text-center  px-5">
                                <div class="liquid-counter-element mb-2 " data-fittext="true" data-fittext-options='{ "compressor": 0.5, "minFontSize": 55, "maxFontSize": 55 }' data-enable-counter="true" data-counter-options='{"targetNumber":"490+","blurEffect":true}'>
                                    <span>30</span>
                                </div>
                                <span class="liquid-counter-text liquid-text-bottom"><strong>Countries Where We Have Clients</strong></span>
                            </div>
                        </div>
                    </div>
                </div>
            
        </Section>
    );
}

export default CPANumbers;
