import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import SectionButton from "./SectionButton";
import "./HeroSection.scss";

function OurServices(props) {
    return (
        <Section id="smm-services" className="vc_row pb-90 bg-no-repeat">
            <div className="container">
                <div className="row d-flex flex-wrap align-items-center">
                    <div className="lqd-column col-md-5 mb-30" data-custom-animations="true"
                        data-ca-options='{"triggerHandler":"inview", "animationTarget":"all-childs", "duration":"1600", "delay":"160", "easing":"easeOutQuint", "direction":"forward", "initValues":{"translateY":30, "opacity":0}, "animations":{"translateY":0, "opacity":1}}'>
                        <header className="fancy-title pr-md-4">
                            <h6 className="text-uppercase">Services</h6>
                            <h2>Our <strong>Social Media</strong> Services</h2>
                        </header>
                        <p className="font-size-18 lh-15 mb-55">Our social media expertise brings together data, content, and design to create engaging social experiences that build customer trust and loyalty.</p>
                        <a href="#contact-us"
                            className="btn btn-solid text-uppercase btn-md circle btn-bordered border-thin btn-gradient font-weight-bold px-2"
                            data-localscroll="true">
                            <span>
                                <span className="btn-gradient-bg"></span>
                                <span className="btn-txt">Request a Call</span>
                                <span className="btn-gradient-bg btn-gradient-bg-hover"></span>
                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
                                    space="preserve" className="btn-gradient-border" width="100%" height="100%">
                                    <defs>
                                        <linearGradient id="svg-border-3" x1="0%" y1="0%" x2="100%" y2="0%">
                                            <stop offset="0%"></stop>
                                            <stop offset="100%"></stop>
                                        </linearGradient>
                                    </defs>
                                    <rect x="0.5" y="0.5" rx="29.5" ry="29.5" width="100%" height="100%"
                                        stroke="url(#svg-border-3)"></rect>
                                </svg>
                            </span>
                        </a>
                    </div>
                    <div className="lqd-column col-md-3 col-md-offset-1">
                        <div className="lqd-column-inner" data-parallax="true" data-parallax-from='{"translateY":-30}'
                            data-parallax-to='{"translateY":120}'
                            data-parallax-options='{"duration":"2000", "easing":"linear", "reverse":true, "triggerHook":"onEnter", "overflowHidden": false}'>
                            <div
                                className="iconbox text-left iconbox-round iconbox-lg iconbox-filled iconbox-filled iconbox-filled-hover iconbox-icon-image iconbox-shadow pt-50 pb-40">
                                <div className="iconbox-icon-wrap">
                                    <span className="iconbox-icon-container mb-35">
                                        
                                        <i class="las la-search"></i>
                                    </span>
                                </div>
                                <div className="contents">
                                    <h3>Social Listening</h3>
                                    <p className="font-size-16 lh-165">Discovering what your customers care about</p>
                                </div>
                            </div>
                            <div
                                className="iconbox text-left iconbox-round iconbox-lg iconbox-filled iconbox-filled iconbox-filled-hover iconbox-icon-image iconbox-shadow pt-50 pb-40">
                                <div className="iconbox-icon-wrap">
                                    <span className="iconbox-icon-container mb-35">
                                       
                                        <i class="las la-chart-bar"></i>
                                    </span>
                                </div>
                                <div className="contents">
                                    <h3>Channel Planning</h3>
                                    <p className="font-size-16 lh-165">Choosing the right channels for your brand</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lqd-column col-md-3">
                        <div className="lqd-column-inner" data-parallax="true" data-parallax-from='{"translateY":50}'
                            data-parallax-to='{"translateY":-130}'
                            data-parallax-options='{"duration":"2000", "easing":"linear", "reverse":true, "triggerHook":"onEnter", "overflowHidden": false}'>
                            <div
                                className="iconbox text-left iconbox-round iconbox-lg iconbox-filled iconbox-filled iconbox-filled-hover iconbox-icon-image iconbox-shadow pt-50 pb-40">
                                <div className="iconbox-icon-wrap">
                                    <span className="iconbox-icon-container mb-35">
                                        
                                        <i class="las la-edit"></i>
                                    </span>
                                </div>
                                <div className="contents">
                                    <h3>Content Creation</h3>
                                    <p className="font-size-16 lh-165">Driving audience engagement</p>
                                </div>
                            </div>
                            <div
                                className="iconbox text-left iconbox-round iconbox-lg iconbox-filled iconbox-filled iconbox-filled-hover iconbox-icon-image iconbox-shadow pt-50 pb-40">
                                <div className="iconbox-icon-wrap">
                                    <span className="iconbox-icon-container mb-35">
                                        
                                        <i class="las la-hashtag"></i>
                                    </span>
                                </div>
                                <div className="contents">
                                    <h3>Organic Social</h3>
                                    <p className="font-size-16 lh-165">Establishing a devoted audience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="web-services" className="lqd-space visible-lg visible-md- mb-200"></div>
                <div class="row">
                    <div class="lqd-column col-md-10 col-md-offset-1">
                        <header class="fancy-title text-center mb-75" data-custom-animations="true" data-ca-options='{"triggerHandler":"inview","animationTarget":"all-childs","duration":1200,"delay":100,"initValues":{"translateY":80,"opacity":0},"animations":{"translateY":0,"opacity":1}}'>
                            <h6 class="text-uppercase ltr-sp-2 text-secondary">What We Provide</h6>
                            <h2 class="mt-4 mb-4">Our <strong>Website </strong>Services</h2>
                            <div class="row">
                                <div class="lqd-column col-md-6 col-md-offset-3 px-md-5">
                                    <p class="font-size-18 lh-15">The technical skill and battle-tested processes to deliver high-performing websites. </p>
                                </div>
                            </div>
                        </header>
                    </div>
                </div>
                <div class="row" data-custom-animations="true" data-ca-options='{"triggerHandler":"inview","animationTarget":"all-childs","duration":"1200","delay":"180","initValues":{"scale":0.8,"opacity":0},"animations":{"scale":1,"opacity":1}}'>
                    <div class="lqd-column col-md-6">
                        <div class="iconbox iconbox-side iconbox-semiround iconbox-shadow iconbox-heading-sm iconbox-filled" id="ld_icon_box_5c4e9c4475e68" data-plugin-options='{"color":"#3d59e8"}'>
                            <div class="iconbox-icon-wrap">
                                <span class="iconbox-icon-container">
                                    {/* <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64" enable-background="new 0 0 64 64" space="preserve"> <defs xmlns="http://www.w3.org/2000/svg"> <linearGradient gradientUnits="userSpaceOnUse" id="grad951863" x1="0%" y1="0%" x2="0%" y2="100%"> <stop offset="0%" stop-color="rgb(61, 89, 232)"></stop> <stop offset="100%" stop-color="rgb(61, 89, 232)"></stop> </linearGradient> </defs> <path fill="none" stroke="url(#grad951863)" stroke-width="2" stroke-miterlimit="10" d="M53.92,10.081c12.107,12.105,12.107,31.732,0,43.838 c-12.106,12.108-31.734,12.108-43.84,0c-12.107-12.105-12.107-31.732,0-43.838C22.186-2.027,41.813-2.027,53.92,10.081z"></path> <circle fill="none" stroke="url(#grad951863)" stroke-width="2" stroke-miterlimit="10" cx="32" cy="32" r="22.999"></circle> <circle fill="none" stroke="url(#grad951863)" stroke-width="2" stroke-miterlimit="10" cx="32" cy="32" r="15"></circle> <circle fill="none" stroke="url(#grad951863)" stroke-width="2" stroke-miterlimit="10" cx="32" cy="32" r="6.999"></circle> </svg> */}
                                    <i style={{color:'#3d5ae8'}} class="las la-drafting-compass"></i>
                                </span>
                            </div>
                            <div class="contents">
                                <h3 class="mb-2">Website Design</h3>
                                <p class="font-size-18 lh-15 mb-2">Visual Design, Interactive Mockups, Design Guidelines, Feedback, Revisions</p>
                                <a href="#contact-us" class="line-btns">Request a Free Quote</a>
                            </div>
                        </div>
                    </div>
                    <div class="lqd-column col-md-6">
                        <div class="iconbox iconbox-side iconbox-semiround iconbox-shadow iconbox-heading-sm iconbox-filled" id="ld_icon_box_5c4e9c4475e69" data-plugin-options='{"color":"#28d5a7"}'>
                            <div class="iconbox-icon-wrap">
                                <span class="iconbox-icon-container">
                                    {/* <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64" enable-background="new 0 0 64 64" space="preserve"><defs xmlns="http://www.w3.org/2000/svg"><linearGradient gradientUnits="userSpaceOnUse" id="grad84546" x1="0%" y1="0%" x2="0%" y2="100%"><stop offset="0%" stop-color="rgb(40, 213, 167)"></stop><stop offset="100%" stop-color="rgb(40, 213, 167)"></stop></linearGradient></defs> <polygon fill="none" stroke="url(#grad84546)" stroke-width="2" stroke-miterlimit="10" points="1,63 1,2 62,63 "></polygon> <polygon fill="none" stroke="url(#grad84546)" stroke-width="2" stroke-miterlimit="10" points="14,54 14,30 38,54 "></polygon> <line fill="none" stroke="url(#grad84546)" stroke-width="2" stroke-miterlimit="10" x1="8" y1="22" x2="2" y2="22"></line> <line fill="none" stroke="url(#grad84546)" stroke-width="2" stroke-miterlimit="10" x1="6" y1="30" x2="2" y2="30"></line> <line fill="none" stroke="url(#grad84546)" stroke-width="2" stroke-miterlimit="10" x1="8" y1="38" x2="2" y2="38"></line> <line fill="none" stroke="url(#grad84546)" stroke-width="2" stroke-miterlimit="10" x1="6" y1="46" x2="2" y2="46"></line> <line fill="none" stroke="url(#grad84546)" stroke-width="2" stroke-miterlimit="10" x1="8" y1="54" x2="2" y2="54"></line> <polygon fill="none" stroke="url(#grad84546)" stroke-width="2" stroke-miterlimit="10" points="25,1 19,7 55,43 63,45 61,37 "></polygon> <line fill="none" stroke="url(#grad84546)" stroke-width="2" stroke-miterlimit="10" x1="25" y1="13" x2="31" y2="7"></line> </svg> */}
                                    <i style={{color:'#32d7ac'}} class="las la-code"></i>
                                </span>
                            </div>
                            <div class="contents">
                                <h3 class="mb-2">Website Development</h3>
                                <p class="font-size-18 lh-15 mb-2">Coding, Content Integration, QA Testing, Launch, Optimization</p>
                                <a href="#contact-us" class="line-btns">Request a Free Quote</a>
                            </div>
                        </div>
                    </div>
                    {/* <div class="lqd-column col-md-6">
                        <div class="iconbox iconbox-side iconbox-semiround iconbox-shadow iconbox-heading-sm iconbox-filled" id="ld_icon_box_5c4e9c4475e70" data-plugin-options='{"color":"#f9b851"}'>
                            <div class="iconbox-icon-wrap">
                                <span class="iconbox-icon-container">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64" enable-background="new 0 0 64 64" space="preserve"><defs xmlns="http://www.w3.org/2000/svg"><linearGradient gradientUnits="userSpaceOnUse" id="grad81353" x1="0%" y1="0%" x2="0%" y2="100%"><stop offset="0%" stop-color="rgb(249, 184, 81)"></stop><stop offset="100%" stop-color="rgb(249, 184, 81)"></stop></linearGradient></defs> <path fill="none" stroke="url(#grad81353)" stroke-width="2" stroke-miterlimit="10" d="M32.001,0.887c17.184,0,31.113,13.929,31.112,31.113 C63.114,49.185,49.184,63.115,32,63.113C14.815,63.114,0.887,49.185,0.888,32.001C0.885,14.816,14.815,0.887,32.001,0.887z"></path> <line fill="none" stroke="url(#grad81353)" stroke-width="2" stroke-miterlimit="10" x1="32" y1="1" x2="32" y2="63"></line> <line fill="none" stroke="url(#grad81353)" stroke-width="2" stroke-miterlimit="10" x1="63" y1="32" x2="1" y2="32"></line> <path fill="none" stroke="url(#grad81353)" stroke-width="2" stroke-miterlimit="10" d="M30,1c0,0-14,11-14,31s14,31,14,31"></path> <path fill="none" stroke="url(#grad81353)" stroke-width="2" stroke-miterlimit="10" d="M34,1c0,0,14,11,14,31S34,63,34,63"></path> <path fill="none" stroke="url(#grad81353)" stroke-width="2" stroke-miterlimit="10" d="M8,12c0,0,5,10,24,10s24-10,24-10"></path> <path fill="none" stroke="url(#grad81353)" stroke-width="2" stroke-miterlimit="10" d="M8,52c0,0,5-10,24-10s24,10,24,10"></path> </svg>
                                </span>
                            </div>
                            <div class="contents">
                                <h3 class="mb-2">Newest technology</h3>
                                <p>Produce the highest quality work and services for every client, on every project</p>
                            </div>
                        </div>
                    </div> */}
                    {/* <div class="lqd-column col-md-6">
                        <div class="iconbox iconbox-side iconbox-semiround iconbox-shadow iconbox-heading-sm iconbox-filled" id="ld_icon_box_5c4e9c4475e71" data-plugin-options='{"color":"#fe688b"}'>
                            <div class="iconbox-icon-wrap">
                                <span class="iconbox-icon-container">
                                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64" enable-background="new 0 0 64 64" space="preserve"><defs xmlns="http://www.w3.org/2000/svg"><linearGradient gradientUnits="userSpaceOnUse" id="grad611408" x1="0%" y1="0%" x2="0%" y2="100%"><stop offset="0%" stop-color="rgb(254, 104, 139)"></stop><stop offset="100%" stop-color="rgb(254, 104, 139)"></stop></linearGradient></defs> <circle fill="none" stroke="url(#grad611408)" stroke-width="2" stroke-miterlimit="10" cx="44" cy="38" r="19"></circle> <circle fill="none" stroke="url(#grad611408)" stroke-width="2" stroke-miterlimit="10" cx="44" cy="38" r="13"></circle> <polyline fill="none" stroke="url(#grad611408)" stroke-width="2" stroke-miterlimit="10" points="30,51 1,51 1,57 38,57 38,56 "></polyline> <polyline fill="none" stroke="url(#grad611408)" stroke-width="2" stroke-miterlimit="10" points="27,45 3,45 3,51 30,51 "></polyline> <polyline fill="none" stroke="url(#grad611408)" stroke-width="2" stroke-miterlimit="10" points="26,39 5,39 5,45 27,45 "></polyline> <polyline fill="none" stroke="url(#grad611408)" stroke-width="2" stroke-miterlimit="10" points="26,33 1,33 1,39 26,39 "></polyline> <polyline fill="none" stroke="url(#grad611408)" stroke-width="2" stroke-miterlimit="10" points="29,27 3,27 3,33 26,33 "></polyline> <polyline fill="none" stroke="url(#grad611408)" stroke-width="2" stroke-miterlimit="10" points="35,21 1,21 1,27 29,27 "></polyline> <polyline fill="none" stroke="url(#grad611408)" stroke-width="2" stroke-miterlimit="10" points="40,20 40,15 3,15 3,21 35,21 "></polyline> <rect x="1" y="9" fill="none" stroke="url(#grad611408)" stroke-width="2" stroke-miterlimit="10" width="37" height="6"></rect> </svg>
                                </span>
                            </div>
                            <div class="contents">
                                <h3 class="mb-2">Honest pricing</h3>
                                <p>Produce the highest quality work and services for every client, on every project</p>
                            </div>
                        </div>
                    </div> */}
                </div>
                {/* <div id="works" className="row">
                    <div className="lqd-column col-md-12">
                        <div className="liquid-portfolio-list">
                            <div className="row d-flex flex-wrap align-items-end mb-40">
                                <div className="col-md-6">
                                    <header className="fancy-title mb-0">
                                        <h6 className="text-uppercase">Projects</h6>
                                        <h2><strong>Latest</strong> works</h2>
                                    </header>
                                </div>
                                <div className="col-md-6">
                                    <div className="liquid-filter-items align-items-center justify-content-end mb-10">
                                        <div className="liquid-filter-items-inner">
                                            <ul className="filter-list filter-list-inline size-sm text-uppercase ltr-sp-1"
                                                id="seo-pf-grid">
                                                <li className="active" data-filter="*"><span>All</span></li>
                                                <li data-filter=".custom-print"><span>Custom Print</span></li>
                                                <li data-filter=".digital-design"><span>Digital Design</span></li>
                                                <li data-filter=".ecommerce"><span>Ecommerce</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row liquid-portfolio-list-row" data-liquid-masonry="true"
                                data-masonry-options='{ "filtersID": "#seo-pf-grid" }' data-custom-animations="true"
                                data-ca-options='{"triggerHandler":"inview","animationTarget":".ld-pf-item","animateTargetsWhenVisible":"true","duration":"1600","delay":"160","easing":"easeOutQuint","initValues":{"translateY":65,"opacity":0},"animations":{"translateY":0,"opacity":1}}'>
                                <div
                                    className="col-md-6 col-sm-6 col-xs-12 masonry-item branding digital-design grid-hover-overlay">
                                    <article
                                        className="ld-pf-item ld-pf-dark pf-bg-shadow pf-details-visible pf-details-boxed pf-details-pull-right pf-details-pull-up-half title-size-24 pf-details-h-str pf-details-v-end post-2021 liquid-portfolio type-liquid-portfolio status-publish format-gallery has-post-thumbnail hentry post_format-post-format-gallery liquid-portfolio-category-branding liquid-portfolio-category-digital-design liquid-portfolio-category-grid-hover-overlay">
                                        <div className="ld-pf-inner">
                                            <div className="ld-pf-image">
                                                <figure data-responsive-bg="true" data-parallax="true"
                                                    data-parallax-options='{ "parallaxBG": true }'>
                                                    <img src={require('../assets/demo/portfolio/pf-56.jpg')} alt="Oblivision" />
                                                </figure>
                                            </div>
                                            <div className="ld-pf-details py-3">
                                                <div className="ld-pf-bg" style={{ backgroundColor: '#fff' }}
                                                    data-custom-animations="true"
                                                    data-ca-options='{ "triggerHandler": "mouseenter", "triggerTarget": ".ld-pf-item", "triggerRelation": "closest", "offTriggerHandler": "mouseleave", "animationTarget": "this", "easing": "easeInOutCirc", "duration": 850, "offDuration": 700, "initValues": { "translateY": 0 }, "animations": { "translateY": -70 } }'>
                                                </div>
                                                <div className="ld-pf-details-inner" data-custom-animations="true"
                                                    data-ca-options='{ "triggerHandler": "mouseenter", "triggerTarget": ".ld-pf-item", "triggerRelation": "closest", "offTriggerHandler": "mouseleave", "animationTarget": ".split-inner", "easing": "easeOutQuint", "duration": 850, "offDuration": 750, "startDelay": 280, "offDelay": 280, "delay": 60, "initValues": { "translateY": 0 }, "animations": { "translateY": -70 } }'>
                                                    <h3 className="ld-pf-title h4" data-split-text="true"
                                                        data-split-options='{ "type": "lines" }'>Oblivion</h3>
                                                    <p data-split-text="true"
                                                        data-split-options='{ "type": "lines" }'> BL Fashion new
                                                            branding </p>
                                                </div>
                                            </div>
                                            <a href="#" className="liquid-overlay-link"></a>
                                        </div>
                                    </article>
                                </div>
                                <div
                                    className="col-md-6 col-sm-6 col-xs-12 masonry-item branding custom-print grid-hover-overlay">
                                    <article
                                        className="ld-pf-item ld-pf-dark pf-bg-shadow pf-details-visible pf-details-boxed pf-details-pull-right pf-details-pull-up-half title-size-24 pf-details-h-str pf-details-v-end post-2021 liquid-portfolio type-liquid-portfolio status-publish format-gallery has-post-thumbnail hentry post_format-post-format-gallery liquid-portfolio-category-branding liquid-portfolio-category-digital-design liquid-portfolio-category-grid-hover-overlay">
                                        <div className="ld-pf-inner">
                                            <div className="ld-pf-image">
                                                <figure data-responsive-bg="true" data-parallax="true"
                                                    data-parallax-options='{ "parallaxBG": true }'>
                                                    <img src={require('../assets/demo/portfolio/pf-57.jpg')}></img>
                                                </figure>
                                            </div>
                                            <div className="ld-pf-details py-3">
                                                <div className="ld-pf-bg" style={{ backgroundColor: '#fff' }}
                                                    data-custom-animations="true"
                                                    data-ca-options='{ "triggerHandler": "mouseenter", "triggerTarget": ".ld-pf-item", "triggerRelation": "closest", "offTriggerHandler": "mouseleave", "animationTarget": "this", "easing": "easeInOutCirc", "duration": 850, "offDuration": 700, "initValues": { "translateY": 0 }, "animations": { "translateY": -70 } }'>
                                                </div>
                                                <div className="ld-pf-details-inner" data-custom-animations="true"
                                                    data-ca-options='{ "triggerHandler": "mouseenter", "triggerTarget": ".ld-pf-item", "triggerRelation": "closest", "offTriggerHandler": "mouseleave", "animationTarget": ".split-inner", "easing": "easeOutQuint", "duration": 850, "offDuration": 750, "startDelay": 280, "offDelay": 280, "delay": 60, "initValues": { "translateY": 0 }, "animations": { "translateY": -70 } }'>
                                                    <h3 className="ld-pf-title h4" data-split-text="true"
                                                        data-split-options='{ "type": "lines" }'>Abstract</h3>
                                                    <p data-split-text="true"
                                                        data-split-options='{ "type": "lines" }'>Creative colors for
                                                            brands</p>
                                                </div>
                                            </div>
                                            <a href="#" className="liquid-overlay-link"></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12 masonry-item ecommerce grid-hover-overlay">
                                    <article
                                        className="ld-pf-item ld-pf-dark pf-bg-shadow pf-details-visible pf-details-boxed pf-details-pull-right pf-details-pull-up-half title-size-24 pf-details-h-str pf-details-v-end post-2021 liquid-portfolio type-liquid-portfolio status-publish format-gallery has-post-thumbnail hentry post_format-post-format-gallery liquid-portfolio-category-branding liquid-portfolio-category-digital-design liquid-portfolio-category-grid-hover-overlay">
                                        <div className="ld-pf-inner">
                                            <div className="ld-pf-image">
                                                <figure data-responsive-bg="true" data-parallax="true"
                                                    data-parallax-options='{ "parallaxBG": true }'>
                                                    <img src={require('../assets/demo/portfolio/pf-58.jpg')}></img>
                                                </figure>
                                            </div>
                                            <div className="ld-pf-details py-3">
                                                <div className="ld-pf-bg" style={{ backgroundColor: '#fff' }}
                                                    data-custom-animations="true"
                                                    data-ca-options='{ "triggerHandler": "mouseenter", "triggerTarget": ".ld-pf-item", "triggerRelation": "closest", "offTriggerHandler": "mouseleave", "animationTarget": "this", "easing": "easeInOutCirc", "duration": 850, "offDuration": 700, "initValues": { "translateY": 0 }, "animations": { "translateY": -70 } }'>
                                                </div>
                                                <div className="ld-pf-details-inner" data-custom-animations="true"
                                                    data-ca-options='{ "triggerHandler": "mouseenter", "triggerTarget": ".ld-pf-item", "triggerRelation": "closest", "offTriggerHandler": "mouseleave", "animationTarget": ".split-inner", "easing": "easeOutQuint", "duration": 850, "offDuration": 750, "startDelay": 280, "offDelay": 280, "delay": 60, "initValues": { "translateY": 0 }, "animations": { "translateY": -70 } }'>
                                                    <h3 className="ld-pf-title h4" data-split-text="true"
                                                        data-split-options='{ "type": "lines" }'>Creative Tropical
                                                        </h3>
                                                    <p data-split-text="true"
                                                        data-split-options='{ "type": "lines" }'>Winter is coming
                                                        </p>
                                                </div>
                                            </div>
                                            <a href="#" className="liquid-overlay-link"></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12 masonry-item ecommerce grid-hover-overlay">
                                    <article
                                        className="ld-pf-item ld-pf-dark pf-bg-shadow pf-details-visible pf-details-boxed pf-details-pull-right pf-details-pull-up-half title-size-24 pf-details-h-str pf-details-v-end post-2021 liquid-portfolio type-liquid-portfolio status-publish format-gallery has-post-thumbnail hentry post_format-post-format-gallery liquid-portfolio-category-branding liquid-portfolio-category-digital-design liquid-portfolio-category-grid-hover-overlay">
                                        <div className="ld-pf-inner">
                                            <div className="ld-pf-image">
                                                <figure data-responsive-bg="true" data-parallax="true"
                                                    data-parallax-options='{ "parallaxBG": true }'>
                                                    <img src={require('../assets/demo/portfolio/pf-58.jpg')}></img>
                                                </figure>
                                            </div>
                                            <div className="ld-pf-details py-3">
                                                <div className="ld-pf-bg" style={{ backgroundColor: '#fff' }}
                                                    data-custom-animations="true"
                                                    data-ca-options='{ "triggerHandler": "mouseenter", "triggerTarget": ".ld-pf-item", "triggerRelation": "closest", "offTriggerHandler": "mouseleave", "animationTarget": "this", "easing": "easeInOutCirc", "duration": 850, "offDuration": 700, "initValues": { "translateY": 0 }, "animations": { "translateY": -70 } }'>
                                                </div>
                                                <div className="ld-pf-details-inner" data-custom-animations="true"
                                                    data-ca-options='{ "triggerHandler": "mouseenter", "triggerTarget": ".ld-pf-item", "triggerRelation": "closest", "offTriggerHandler": "mouseleave", "animationTarget": ".split-inner", "easing": "easeOutQuint", "duration": 850, "offDuration": 750, "startDelay": 280, "offDelay": 280, "delay": 60, "initValues": { "translateY": 0 }, "animations": { "translateY": -70 } }'>
                                                    <h3 className="ld-pf-title h4" data-split-text="true"
                                                        data-split-options='{ "type": "lines" }'>Creative Tropical
                                                        </h3>
                                                    <p data-split-text="true"
                                                        data-split-options='{ "type": "lines" }'>Winter is coming
                                                        </p>
                                                </div>
                                            </div>
                                            <a href="#" className="liquid-overlay-link"></a>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </Section>
    );
}

export default OurServices;
