import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import SectionButton from "./SectionButton";
import "./HeroSection.scss";
import $ from "jquery";

function FloatingCTA(props) {
    $( document ).ready(function() {
        $('.floating-cta').hide();
        $('.floating-cta .close-btn').click(function(){
            $('.floating-cta').hide();
        });
    });
    
    $(window).scroll(function(){
        if ($(window).scrollTop() >= 300) {
            $('.floating-cta').show(500);
        }
        else {
            $('.floating-cta').hide(500);
        }
    });
  return (
    <Section id="floating-cta" className="floating-cta">
        <div className="floating-content">
            
            <div className="iconbox text-left iconbox-xl iconbox-icon-image iconbox-shadow border-radius-5 px-4 pt-40 pb-40 mr-md-3">
                <div className="close-btn"><i className="la la-times"></i></div>
                {/* <div className="iconbox-icon-wrap">
                    <span className="iconbox-icon-container mb-35">
                        <i style={{color:'#fd5d4b'}} class="la la-twitter"></i>
                    </span>
                </div> */}
                <div className="contents">
                    <h3>Talk to us about your next project.</h3>
                    {/* <p><span style={{fontSize:'18px', lineHeight:'30px'}}>Prospects are people too. Social Media Marketing should be scientific, but never robotic.</span></p> */}
                    
                </div>
                <a href="#contact-us"
                        className="btn btn-solid text-uppercase btn-md circle btn-bordered border-thin btn-gradient font-weight-bold px-2"
                        data-localscroll="true">
                        <span>
                            <span className="btn-gradient-bg"></span>
                            <span className="btn-txt">Request A Quote</span>
                            <span className="btn-gradient-bg btn-gradient-bg-hover"></span>
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
                                space="preserve" className="btn-gradient-border" width="100%" height="100%">
                                <defs>
                                    <linearGradient id="svg-border-2" x1="0%" y1="0%" x2="100%" y2="0%">
                                        <stop offset="0%"></stop>
                                        <stop offset="100%"></stop>
                                    </linearGradient>
                                </defs>
                                <rect x="0.5" y="0.5" rx="29.5" ry="29.5" width="100%" height="100%"
                                    stroke="url(#svg-border-2)"></rect>
                            </svg>
                        </span>
                    </a>
            </div>
        </div>
    </Section>
  );
}

export default FloatingCTA;
