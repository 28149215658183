import React, { useState } from "react";
import Section from "./Section";
import CPAContactForm from "./CPAContactForm";
import cpacontact from "./../util/cpa-contact.js";
import SectionHeader from "./SectionHeader";
import SectionButton from "./SectionButton";
import "./HeroSection.scss";


function CPACTA(props) {
const [status, setStatus] = useState();

  const onSubmit = ({ name, email, message, phone }) => {
    setStatus({ type: "pending" });

    cpacontact.submit({ name, email, message, phone }).then(() => {
      setStatus({
        type: "success",
        message: "Your message has been sent! We'll get back to you soon."
      });
    });
  };
    return (
        <Section id="contact-us" className="cpa-contact vc_row pt-110 pb-120 mt-40" data-parallax="true" data-parallax-options='{ "parallaxBG": true }'>
            
                <div class="container">
                    <div class="row align-items-center justify-content-between d-flex">
                        <div class="lqd-column col-md-5" data-custom-animations="true" data-ca-options='{"triggerHandler":"inview","animationTarget":"all-childs","duration":"1200","delay":"160","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":52,"translateZ":-142,"rotateX":-64,"opacity":0},"animations":{"translateY":0,"translateZ":0,"rotateX":0,"opacity":1}}'>
                            <header class="fancy-title">
                                <h2 class="mt-0 mb-4 text-white font-weight-bold" data-fittext="true" data-fittext-options='{ "compressor": 0.65,"maxFontSize": 72,"minFontSize": 30 }' data-split-text="true" data-split-options='{"type":"lines"}'>Request a Free Call Back</h2>
                                <p class="mb-60 font-size-24 text-white" data-split-text="true" data-split-options='{"type":"lines"}'>All consultants are able to give you a free call back at any time.</p>
                            </header>
                            <div class="two-btns">
                                <div class="col-now-btn">
                                    <a href="tel:+16043051535">
                                        <i class="fa fa-phone"></i>
                                        <p>+1 (604) 305 1535</p>
                                    </a>
                                </div>
                                <div class="email-us-btn">
                                    <a href="mailto:hello@embtelsolutions.com">
                                        <i class="fa fa-envelope"></i>
                                        <p>hello@embtelsolutions.ca</p>
                                    </a>
                                </div>
                            </div>
                            {/* <a href="#" class="btn btn-solid text-uppercase circle btn-bordered border-thin font-size-16 font-weight-bold ltr-sp-05 px-2">
                                <span>
                                    <span class="btn-txt">Free Consultation</span>
                                </span>
                            </a> */}
                        </div>
                        <div class="lqd-column col-md-5">
                            <div class="lqd-column-inner bg-white border-radius-6 px-3 px-md-4 pt-40 pb-40">
                                {/* <div class="contact-form contact-form-inputs-filled contact-form-button-block font-size-14">
                                    <form action="http://avehtml.liquid-themes.com/assets/php/mailer.php" method="post" novalidate="novalidate">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <input class="bg-gray text-dark" type="text" name="name" aria-required="true" aria-invalid="false" placeholder="First Name" required />
                                            </div>
                                            <div class="col-md-12">
                                                <input class="bg-gray text-dark" type="email" name="email" aria-required="true" aria-invalid="false" placeholder="Your email address" required />
                                            </div>
                                            <div class="col-md-12">
                                                <input class="bg-gray text-dark" type="tel" name="mobile" aria-required="true" aria-invalid="false" placeholder="Mobile no" required />
                                            </div>
                                            <div class="col-md-12 text-md-right">
                                                <input type="submit" value="Submit form" />
                                            </div>
                                        </div>
                                    </form>
                                    <div class="contact-form-result hidden"></div>
                                </div> */}
                                <div class="contact-form contact-form-inputs-underlined contact-form-button-circle mb-0">
                                    <CPAContactForm
                                        parentColor={props.parentColor}
                                        showNameField={props.showNameField}
                                        buttonText="Send Message"
                                        onSubmit={onSubmit}
                                        status={status}
                                    />
                                    {/* <form action="https://formspree.io/mdolrqww" method="post" novalidate="novalidate">
                                        <input class="lh-25 mb-30" type="text" name="name" aria-required="true" aria-invalid="false" placeholder="First Name" required />
                                        <input class="lh-25 mb-30" type="email" name="email" aria-required="true" aria-invalid="false" placeholder="Your email address" required />
                                        <input class="lh-25 mb-30" type="tel" name="mobile" aria-required="true" aria-invalid="false" placeholder="Mobile no" required />
                                        <ReCAPTCHA
                                            sitekey="6LejmO0UAAAAAPPejFSKn6y1vX4l0DsNRUl3HdNb"
                                            size="invisible"
                                        />
                                        <input style={{width:'100%'}} type="submit" value="Send message" class="font-size-16 justify-content-center ltr-sp-1 font-weight-bold text-center" />
                                    </form> */}
                                    <div class="contact-form-result hidden"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
        </Section>
    );
}

export default CPACTA;
