import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import SectionButton from "./SectionButton";
import "./HeroSection.scss";

function CPAAbout(props) {
    return (
        <Section id="cpa-process" className="vc_row bg-no-repeat pt-70 pb-70">
            <div class="pb-150">
                <div class="container">
                    <div class="row d-flex flex-wrap align-items-center">
                        <div class="lqd-column col-md-6" data-custom-animations="true" data-ca-options='{"triggerHandler":"inview","animationTarget":"all-childs","duration":"1200","delay":"150","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":31,"translateZ":-108,"opacity":0},"animations":{"translateY":0,"translateZ":0,"opacity":1}}'>
                            {/* <figure>
                                <img src="assets/img/logo/logo-1.svg" alt="Ave" />
                            </figure> */}
                                <h2 class="mt-2 mb-3 font-weight-normal lh-1" data-fittext="true" data-fittext-options='{ "compressor": 0.65, "maxFontSize": 60, "minFontSize": 45 }' data-split-text="true" data-split-options='{"type":"lines"}'>
                                    <strong>Embtel Solutions</strong> knows <br />
                                    <strong>Web Design</strong> 
                                </h2>
                                    <p class="h3 font-weight-normal pr-md-7 mr-md-7 mb-40"><i>We know that good design means good business.</i></p>
                                    <p class="pr-md-7 mr-md-7 mb-60">We help our clients succeed by creating brand identities, digital experiences, and print materials that communicate clearly, achieve marketing goals, and look fantastic.</p>
                                    <a href="#contact-us" class="btn btn-solid text-uppercase btn-md circle btn-bordered border-thin font-size-14 font-weight-bold ltr-sp-025 px-2" data-localscroll="true">
                                        <span>
                                            <span class="btn-txt">Request a Call Back</span>
                                        </span>
                                    </a>
                        </div>
                        <div class="lqd-column col-md-5 col-md-offset-1">
                            <div class="lqd-parallax-images">
                                <div class="liquid-img-group-single" data-shadow-style="4" data-inview="true" data-animate-shadow="true">
                                    <div class="liquid-img-group-img-container">
                                        <div class="liquid-img-container-inner" data-parallax="true" data-parallax-from='{"translateY":78}' data-parallax-to='{"translateY":-78}' data-parallax-options='{"overflowHidden":false,"easing":"linear"}'>
                                            <figure>
                                                <img src={require('../assets/demo/misc/parallax-img-1.jpg')} alt="Good Design" />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                                <div class="liquid-img-group-single w-75" data-shadow-style="4" data-inview="true" data-animate-shadow="true">
                                    <div class="liquid-img-group-img-container">
                                        <div class="liquid-img-container-inner" data-parallax="true" data-parallax-from='{"translateY":107}' data-parallax-to='{"translateY":-134}' data-parallax-options='{"overflowHidden":false,"easing":"linear"}'>
                                            <figure>
                                                <img src={require('../assets/demo/misc/parallax-img-2.jpg')} alt="Good Design" />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                    <div className="container">
                        <div className="row" data-custom-animations="true"
                            data-ca-options='{"triggerHandler":"inview", "animationTarget":"all-childs", "duration":"1600", "delay":"160", "easing":"easeOutQuint", "initValues":{"translateY": 100, "opacity":0}, "animations":{"translateY": 0, "opacity":1}}'>
                            <div className="lqd-column col-md-12 mb-60">
                                <header className="fancy-title">
                                    <h6 className="text-uppercase">What We Provide</h6>
                                    <h2>A<strong> CPA Website</strong> Can Include</h2>
                                </header>
                            </div>
                            <div className="lqd-column col-md-4">
                                <div className="iconbox text-left iconbox-xl iconbox-icon-image iconbox-shadow border-radius-5 px-4 pt-40 pb-55 mr-md-3">
                                    <div className="iconbox-icon-wrap">
                                        <span className="iconbox-icon-container mb-35">
                                            <i style={{ color: '#fd5d4b' }} class="la la-search-dollar"></i>
                                        </span>
                                    </div>
                                    <div className="contents">
                                        <h3>Tax Payment Calculator </h3>
                                        <p><span style={{ fontSize: '18px', lineHeight: '30px' }}>Are your clients interested in how much they pay in taxes? A custom tax calculator can show potential clients their potential payments or refunds.</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="lqd-column col-md-4">
                                <div
                                    className="iconbox text-left iconbox-xl iconbox-icon-image iconbox-shadow border-radius-5 px-4 pt-40 pb-55 mx-md-2">
                                    <div className="iconbox-icon-wrap">
                                        <span className="iconbox-icon-container mb-45">
                                            <i style={{ color: '#fd5d4b' }} class="la la-drafting-compass"></i>
                                        </span>
                                    </div>

                                    <div className="contents">
                                        <h3>Downloadable Content</h3>
                                        <p><span style={{ fontSize: '18px', lineHeight: '30px' }}>Providing your website visitors with forms and other downloadable content can create a good experience for your users.</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="lqd-column col-md-4">
                                <div
                                    className="iconbox text-left iconbox-xl iconbox-icon-image iconbox-shadow border-radius-5 px-4 pt-40 pb-55 ml-md-3">
                                    <div className="iconbox-icon-wrap">
                                        <span className="iconbox-icon-container mb-45">
                                            <i style={{ color: '#fd5d4b' }} class="la la-smile"></i>
                                        </span>
                                    </div>
                                    <div className="contents">
                                        <h3>Consultation Scheduling</h3>
                                        <p><span style={{ fontSize: '18px', lineHeight: '30px' }}>A custom contact form to schedule consultations will help your website visitors convert into clients.</span></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 text-center mt-50">
                                <a href="#cpa-hero" class="btn btn-solid text-uppercase circle btn-bordered border-thin font-size-14 font-weight-semibold" data-localscroll="true">
                                    <span>
                                        <span class="btn-txt">Free Consultation</span>
                                    </span>
                                </a>
                            </div>


                        </div>
                        <div id="about" className="lqd-space visible-lg visible-md" style={{ height: '150px' }}></div>

                        <div class="container">
                            <div class="row d-flex flex-wrap align-items-center">
                                <div class="lqd-column col-lg-6 col-md-5">
                                    <div style={{ borderRadius: '10px' }} class="liquid-img-group-single stretch-to-left" data-shadow-style="4" data-roundness="10" data-inview="true" data-animate-shadow="true" data-reveal="true" data-reveal-options='{"direction":"rl","bgcolor":"rgb(25, 38, 47)"}'>
                                        <div class="liquid-img-group-img-container">
                                            <div class="liquid-img-container-inner">
                                                <figure>
                                                    <img src={require('../assets/demo/banners/banner-101.jpg')} alt="Banner" />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="lqd-column col-md-5 hidden-xs hidden-sm">
                                <div class="lqd-parallax-team-members">
                                    <div class="liquid-img-group-container">
                                        <div class="liquid-img-group-inner">
                                            <div class="liquid-img-group-single" data-roundness="4">
                                                <div class="liquid-img-group-img-container">
                                                    <div class="liquid-img-container-inner" data-parallax="true" data-parallax-from='{"translateY":47}' data-parallax-to='{"translateY":-74}' data-parallax-options='{"overflowHidden": false, "easing": "linear"}'>
                                                        <figure>
                                                            <img src={require('../assets/demo/img-1.jpg')} alt="Team Member" />
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="liquid-img-group-container">
                                        <div class="liquid-img-group-inner">
                                            <div class="liquid-img-group-single" data-roundness="4">
                                                <div class="liquid-img-group-img-container">
                                                    <div class="liquid-img-container-inner" data-parallax="true" data-parallax-from='{"translateY":83}' data-parallax-to='{"translateY":-94}' data-parallax-options='{"overflowHidden": false, "easing": "linear"}'>
                                                        <figure>
                                                            <img src={require('../assets/demo/img-2.jpg')} alt="Team Member" />
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="liquid-img-group-container">
                                        <div class="liquid-img-group-inner">
                                            <div class="liquid-img-group-single" data-roundness="4">
                                                <div class="liquid-img-group-img-container">
                                                    <div class="liquid-img-container-inner" data-parallax="true" data-parallax-from='{"translateY":167}' data-parallax-to='{"translateY":-151}' data-parallax-options='{"overflowHidden": false, "easing": "linear"}'>
                                                        <figure>
                                                            <img src={require('../assets/demo/img-3.jpg')} alt="Team Member" />
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                                <div class="lqd-column col-md-5 col-md-offset-1" data-custom-animations="true" data-ca-options='{"triggerHandler":"inview","animationTarget":"all-childs","duration":1200,"delay":160,"initValues":{"translateY":50,"opacity":0},"animations":{"translateY":0,"opacity":1}}'>
                                    <header class="fancy-title mb-60">
                                        <h6 class="text-uppercase ltr-sp-2 font-size-12 font-weight-semibold text-secondary">ABOUT US</h6>
                                        <h2 class="mt-4 mb-4">Why Choose<strong> Embtel Solutions</strong></h2>
                                        <p>At Embtel Solutions, we not only create a gorgeous website for you, but our team can also provide ongoing digital marketing services for you including pay-per-click advertising, search engine optimization, and more!</p>
                                        <p>Our goal is to help your CPA firm attract, engage, and convert more prospective businesses and individuals looking for an accountant to help them with their financial planning, taxes, or audits.</p>
                                    </header>
                                    <a href="tel:+16043051535" class="btn btn-solid text-uppercase circle btn-bordered border-thin font-size-14 font-weight-semibold" data-localscroll="true" data-localscroll-options='{"scrollBelowSection":true}'>
                                        <span>
                                            <span class="btn-txt">Call Us Know</span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
        </Section>
    );
}

export default CPAAbout;
