import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import SectionButton from "./SectionButton";
import "./HeroSection.scss";

function HeroSection(props) {
  return (
    <Section id="testimonials" className="vc_row bg-no-repeat bg-sm-none pt-90 pb-50">
        <div class="container">
                    <div class="row">
                        <div class="lqd-column col-md-4 visible-xs visible-sm text-center">
                            <div class="ld-fancy-heading mask-text">
                                <h6 class="mb-0 font-weight-light lh-1" data-fittext="true"
                                    data-fittext-options='{"compressor":0.55,"maxFontSize":"100", "minFontSize":"100"}'
                                    data-split-text="true" data-custom-animations="true"
                                    data-ca-options='{"triggerHandler":"inview", "animationTarget":".split-inner", "duration":"1600", "delay":"160", "easing":"easeOutQuint", "direction":"forward", "initValues":{"translateX":90}, "animations":{"translateX":0}}'
                                    data-split-options='{"type":"chars, words"}'>
                                    <span class="ld-fh-txt"> 99</span>
                                </h6>
                            </div>
                            <header class="fancy-title pr-md-4">
                                <h6 class="text-uppercase">Testimonials</h6>
                                <h2><strong>Trusted from</strong> our clients</h2>
                            </header>
                        </div>
                        <div class="lqd-column col-md-8">
                            <div class="lqd-space visible-md visible-lg" style={{height:'400px'}}></div>
                            <div class="row">
                                <div class="lqd-column col-md-9 text-center">
                                    <div
                                        class="carousel-container carousel-nav-left carousel-nav-md carousel-dots-style1">
                                        <div class="carousel-items row"
                                            data-lqd-flickity='{"wrapAround": true, "pageDots": true}'>
                                            <div class="carousel-item col-xs-12 mb-9">
                                                <p class="font-size-30 lh-16 text-black h4 mb-5">“When working with Embtel solutions you can relax and know you will get excellent service. “</p>
                                                <h3 class="mt-0 mb-2">Simi K.</h3>
                                                <h5 class="my-0 opacity-06">Fremont, CA</h5>
                                            </div>
                                            <div class="carousel-item col-xs-12 mb-9">
                                                <p class="font-size-30 lh-16 text-black h4 mb-5">“Fenil has the patience, encouraged and enhanced creativity, managed to always helpfully explain the process of building our website through the year.“</p>
                                                <h3 class="mt-0 mb-2">Archanaa S.</h3>
                                                <h5 class="my-0 opacity-06">Pleasant Hill, CA</h5>
                                            </div>
                                            <div class="carousel-item col-xs-12 mb-9">
                                                <p class="font-size-30 lh-16 text-black h4 mb-5">“This is really a 5 star review. With thousands of pages, and legacy issues, rebuilding our website was a particularly complex project.“</p>
                                                <h3 class="mt-0 mb-2">Chuck R.</h3>
                                                <h5 class="my-0 opacity-06">San Francisco, CA</h5>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="lqd-column col-md-4 visible-md visible-lg">
                            <div class="ld-fancy-heading mask-text">
                                <h6 class="mb-0 font-weight-light" data-fittext="true"
                                    data-fittext-options='{"compressor":0.55,"maxFontSize":"100", "minFontSize":"100"}'
                                    data-split-text="true" data-custom-animations="true"
                                    data-ca-options='{"triggerHandler":"inview", "animationTarget":".split-inner", "duration":"1600", "delay":"160", "easing":"easeOutQuint", "direction":"forward", "initValues":{"translateX":90}, "animations":{"translateX":0}}'
                                    data-split-options='{"type":"chars, words"}'>
                                    <span class="ld-fh-txt"> 99</span>
                                </h6>
                            </div>
                            <header class="fancy-title pr-md-4">
                                <h6 class="text-uppercase">Testimonials</h6>
                                <h2><strong>Trusted from</strong> our clients</h2>
                            </header>
                        </div>
                        <div class="lqd-column col-md-12 visible-md visible-lg">
                            <div class="liquid-img-group-container lqd-parallax-images-9">
                                <div class="liquid-img-group-inner">
                                    <div class="liquid-img-group-single">
                                        <div class="liquid-img-group-img-container">
                                            <div class="liquid-img-container-inner" data-parallax="true"
                                                data-parallax-from='{"translateY":60}'
                                                data-parallax-to='{"translateY":-160}'
                                                data-parallax-options='{"overflowHidden":false,"easing":"linear"}'>
                                                <figure>
                                                    
                                                    <img src={require('../assets/demo/misc/parallax-img-18.png')} alt="Avatar" />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="liquid-img-group-single">
                                        <div class="liquid-img-group-img-container">
                                            <div class="liquid-img-container-inner" data-parallax="true"
                                                data-parallax-from='{"translateY":130}'
                                                data-parallax-to='{"translateY":-125}'
                                                data-parallax-options='{"overflowHidden":false,"easing":"linear"}'>
                                                <figure>
                                                <img src={require('../assets/demo/misc/parallax-img-19.png')} alt="Avatar" />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="liquid-img-group-single">
                                        <div class="liquid-img-group-img-container">
                                            <div class="liquid-img-container-inner" data-parallax="true"
                                                data-parallax-from='{"translateY":60}'
                                                data-parallax-to='{"translateY":-60}'
                                                data-parallax-options='{"overflowHidden":false,"easing":"linear"}'>
                                                <figure>
                                                <img src={require('../assets/demo/misc/parallax-img-20.png')} alt="Avatar" />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="liquid-img-group-single">
                                        <div class="liquid-img-group-img-container">
                                            <div class="liquid-img-container-inner" data-parallax="true"
                                                data-parallax-from='{"translateY":120}'
                                                data-parallax-to='{"translateY":-265}'
                                                data-parallax-options='{"overflowHidden":false,"easing":"linear"}'>
                                                <figure>
                                                <img src={require('../assets/demo/misc/parallax-img-21.png')} alt="Avatar" />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="liquid-img-group-single">
                                        <div class="liquid-img-group-img-container">
                                            <div class="liquid-img-container-inner" data-parallax="true"
                                                data-parallax-from='{"translateY":40}'
                                                data-parallax-to='{"translateY":-90}'
                                                data-parallax-options='{"overflowHidden":false,"easing":"linear"}'>
                                                <figure>
                                                <img src={require('../assets/demo/misc/parallax-img-22.png')} alt="Avatar" />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="lqd-space visible-md visible-lg" style={{height:'400px'}}></div>
                </div>
    </Section>
  );
}

export default HeroSection;
