import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import SectionButton from "./SectionButton";
import "./HeroSection.scss";

function HeroSection(props) {
  return (
    <Section id="main-slider" className="vc_row bg-no-repeat fullheight d-flex align-items-center py-5">
      <div class="lqd-particles-bg-wrap">
          <div class="ld-particles-container">
              <div class="ld-particles-inner" id="particles-1559739661542-e9d04c39-a9eb" data-particles="true"
                  data-particles-options='{"particles":{"number":{"value":4}, "color":{"value":["#fdc14c", "#fd5c4c", "#48bb0f"]}, "shape":{"type":["circle"]}, "opacity":{"random":true, "anim":{"enable":true, "opacity_min":0.80000000000000004, "speed":1, "sync":true}}, "size":{"value":5, "random":true, "anim":{"enable":true, "size_min":52}}, "move":{"enable":true, "direction":"none", "speed":1, "random":true, "out_mode":"out"}}, "interactivity":[]}'>
              </div>
          </div>
      </div>
      <div class="container">
                    <div class="row d-flex flex-wrap align-items-center">
                        <div class="lqd-column col-lg-5 col-md-6">
                            <div class="ld-fancy-heading mask-text text-uppercase">
                                <h6 class=" font-size-20 ltr-sp-01" data-split-text="true" data-custom-animations="true"
                                    data-ca-options='{"triggerHandler":"inview", "animationTarget":".split-inner", "duration":"1600", "delay":100, "easing":"easeOutQuint", "direction":"forward", "initValues":{"translateY":60}, "animations":{"translateY":0}}'
                                    data-split-options='{"type":"lines"}'>
                                    <span class="ld-fh-txt"> Embtel Solutions</span>
                                </h6>
                            </div>
                            <div class="ld-fancy-heading mask-text">
                                <h2 data-fittext="true"
                                    data-fittext-options='{"compressor":0.55,"maxFontSize":"60", "minFontSize":"48"}'
                                    data-split-text="true" data-custom-animations="true"
                                    data-ca-options='{"triggerHandler":"inview", "animationTarget":".split-inner", "duration":"1600", "startDelay":"200", "delay":"160", "easing":"easeOutQuint", "direction":"forward", "initValues":{"translateY":150}, "animations":{"translateY":0}}'
                                    data-split-options='{"type":"lines"}'>
                                    <span class="ld-fh-txt">Demand More From Your Website</span>
                                </h2>
                            </div>
                            <div class="ld-fancy-heading mask-text mb-4">
                                <p class="font-size-18 lh-165" data-split-text="true" data-custom-animations="true"
                                    data-ca-options='{"triggerHandler":"inview","animationTarget":".split-inner","duration":"1600","startDelay":"650","delay":"160","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":60},"animations":{"translateY":0}}'
                                    data-split-options='{"type":"lines"}'>
                                    <span class="ld-fh-txt">We merge imagination and technology to help brands grow in an age of digital transformation.</span>
                                </p>
                            </div>
                            <div class="row">
                                <div class="lqd-column col-md-12" data-custom-animations="true"
                                    data-ca-options='{"triggerHandler":"inview", "animationTarget":"all-childs", "duration":"1600", "startDelay":"1300", "delay":"160", "easing":"easeOutQuint", "direction":"forward", "initValues":{"translateY":40, "opacity":0}, "animations":{"translateY":0, "opacity":1}}'>
                                    <a href="#contact-us"
                                        class="btn btn-solid text-uppercase btn-md circle btn-bordered border-thin btn-gradient px-2"
                                        data-localscroll="true" data-localscroll-options='{"scrollBelowSection":true}'>
                                        <span>
                                            <span class="btn-gradient-bg"></span>
                                            <span class="btn-txt">Request Free Consultation</span>
                                            <span class="btn-gradient-bg btn-gradient-bg-hover"></span>
                                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" space="preserve"
                                                class="btn-gradient-border" width="100%" height="100%">
                                                <defs>
                                                    <linearGradient id="svg-border-1" x1="0%" y1="0%" x2="100%" y2="0%">
                                                        <stop offset="0%"></stop>
                                                        <stop offset="100%"></stop>
                                                    </linearGradient>
                                                </defs>
                                                <rect x="0.5" y="0.5" rx="29.5" ry="29.5" width="100%" height="100%"
                                                    stroke="url(#svg-border-1)"></rect>
                                            </svg>
                                        </span>
                                    </a>
                                    {/* <a href="https://www.youtube.com/watch?v=LjaKHqDbzSA"
                                        class="btn btn-naked text-uppercase fresco">
                                        <span>
                                            <span class="btn-txt">See live demo</span>
                                            <span class="btn-icon font-size-30 text-primary">
                                                <i class="icon-music_play_button"></i>
                                            </span>
                                        </span>
                                    </a> */}
                                </div>
                            </div>
                        </div>
                        <div class="lqd-column col-lg-7 col-md-6 visible-md visible-lg">
                            <div class="liquid-img-group-container lqd-parallax-images-7" data-custom-animations="true"
                                data-ca-options='{"triggerHandler":"inview", "animationTarget":".liquid-img-group-single", "duration":"1600", "startDelay":"1150", "delay":"200", "easing":"easeOutQuint", "direction":"forward", "initValues":{"translateX":40, "translateY":191, "rotateZ":7, "opacity":0}, "animations":{"translateX":0, "translateY":0, "rotateZ":0, "opacity":1}}'>
                                <div class="liquid-img-group-inner">
                                    {/* <img src={require('../assets/img/img-2.png')}></img> */}
                                    {/* <div class="liquid-img-group-single">
                                        <div class="liquid-img-group-img-container">
                                            <div class="liquid-img-container-inner" data-parallax="true"
                                                data-parallax-from='{"translateY":2}'
                                                data-parallax-to='{"translateY":120}'
                                                data-parallax-options='{"overflowHidden":false,"easing":"linear"}'>
                                                <figure>
                                                    <img src={require('../assets/demo/misc/phone-1.png')} alt="Mobile Phone" />
                                                </figure>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div class="liquid-img-group-single">
                                        <div class="liquid-img-group-img-container">
                                            <div class="liquid-img-container-inner" data-parallax="true"
                                                data-parallax-from='{"translateY":30}'
                                                data-parallax-to='{"translateY":70}'
                                                data-parallax-options='{"overflowHidden":false,"easing":"linear"}'>
                                                <figure>
                                                    {/* <img src={require('../assets/demo/misc/laptop-1.png')} alt="Laptop" /> */}
                                                    <img src={require('../assets/img/img-2.png')} alt="Laptop" />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
      
    </Section>
  );
}

export default HeroSection;
