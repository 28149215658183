import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import SectionButton from "./SectionButton";
import "./HeroSection.scss";

function OurPortfolio(props) {
    return (
        <Section id="our-portfolio" className="vc_row pb-90 bg-no-repeat">
            <div className="container">
            <div id="works" className="row">
                    <div className="lqd-column col-md-12">
                        <div className="liquid-portfolio-list">
                            <div className="row d-flex flex-wrap align-items-end mb-40">
                                <div className="col-md-6">
                                    <header className="fancy-title mb-0">
                                        <h6 className="text-uppercase">Projects</h6>
                                        <h2><strong>Latest</strong> works</h2>
                                    </header>
                                </div>
                                <div className="col-md-6">
                                    {/* <div className="liquid-filter-items align-items-center justify-content-end mb-10">
                                        <div className="liquid-filter-items-inner">
                                            <ul className="filter-list filter-list-inline size-sm text-uppercase ltr-sp-1"
                                                id="seo-pf-grid">
                                                <li className="active" data-filter="*"><span>All</span></li>
                                                <li data-filter=".custom-print"><span>Custom Print</span></li>
                                                <li data-filter=".digital-design"><span>Digital Design</span></li>
                                                <li data-filter=".ecommerce"><span>Ecommerce</span></li>
                                            </ul>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="row liquid-portfolio-list-row" data-liquid-masonry="true"
                                data-masonry-options='{ "filtersID": "#seo-pf-grid" }' data-custom-animations="true"
                                data-ca-options='{"triggerHandler":"inview","animationTarget":".ld-pf-item","animateTargetsWhenVisible":"true","duration":"1600","delay":"160","easing":"easeOutQuint","initValues":{"translateY":65,"opacity":0},"animations":{"translateY":0,"opacity":1}}'>
                                <div
                                    className="col-md-6 col-sm-6 col-xs-12 masonry-item branding digital-design grid-hover-overlay">
                                    <article
                                        className="ld-pf-item ld-pf-dark pf-bg-shadow pf-details-visible pf-details-boxed pf-details-pull-right pf-details-pull-up-half title-size-24 pf-details-h-str pf-details-v-end post-2021 liquid-portfolio type-liquid-portfolio status-publish format-gallery has-post-thumbnail hentry post_format-post-format-gallery liquid-portfolio-category-branding liquid-portfolio-category-digital-design liquid-portfolio-category-grid-hover-overlay">
                                        <div className="ld-pf-inner">
                                            <div className="ld-pf-image">
                                                <figure data-responsive-bg="true" data-parallax="true"
                                                    data-parallax-options='{ "parallaxBG": true }'>
                                                    <img src={require('../assets/demo/portfolio/recruitfyport.png')} alt="Oblivision" />
                                                </figure>
                                            </div>
                                            <div className="ld-pf-details py-3">
                                                <div className="ld-pf-bg" style={{ backgroundColor: '#fff' }}
                                                    data-custom-animations="true"
                                                    data-ca-options='{ "triggerHandler": "mouseenter", "triggerTarget": ".ld-pf-item", "triggerRelation": "closest", "offTriggerHandler": "mouseleave", "animationTarget": "this", "easing": "easeInOutCirc", "duration": 850, "offDuration": 700, "initValues": { "translateY": 0 }, "animations": { "translateY": -70 } }'>
                                                </div>
                                                <div className="ld-pf-details-inner" data-custom-animations="true"
                                                    data-ca-options='{ "triggerHandler": "mouseenter", "triggerTarget": ".ld-pf-item", "triggerRelation": "closest", "offTriggerHandler": "mouseleave", "animationTarget": ".split-inner", "easing": "easeOutQuint", "duration": 850, "offDuration": 750, "startDelay": 280, "offDelay": 280, "delay": 60, "initValues": { "translateY": 0 }, "animations": { "translateY": -70 } }'>
                                                    <h3 className="ld-pf-title h4" data-split-text="true"
                                                        data-split-options='{ "type": "lines" }'>Recruitfy</h3>
                                                    <p data-split-text="true"
                                                        data-split-options='{ "type": "lines" }'> Angular </p>
                                                </div>
                                            </div>
                                            <a href="https://recruitfy.com" className="liquid-overlay-link" target="_blank"></a>
                                        </div>
                                    </article>
                                </div>
                                <div
                                    className="col-md-6 col-sm-6 col-xs-12 masonry-item branding custom-print grid-hover-overlay">
                                    <article
                                        className="ld-pf-item ld-pf-dark pf-bg-shadow pf-details-visible pf-details-boxed pf-details-pull-right pf-details-pull-up-half title-size-24 pf-details-h-str pf-details-v-end post-2021 liquid-portfolio type-liquid-portfolio status-publish format-gallery has-post-thumbnail hentry post_format-post-format-gallery liquid-portfolio-category-branding liquid-portfolio-category-digital-design liquid-portfolio-category-grid-hover-overlay">
                                        <div className="ld-pf-inner">
                                            <div className="ld-pf-image">
                                                <figure data-responsive-bg="true" data-parallax="true"
                                                    data-parallax-options='{ "parallaxBG": true }'>
                                                    <img src={require('../assets/demo/portfolio/iCompaas.png')}></img>
                                                </figure>
                                            </div>
                                            <div className="ld-pf-details py-3">
                                                <div className="ld-pf-bg" style={{ backgroundColor: '#fff' }}
                                                    data-custom-animations="true"
                                                    data-ca-options='{ "triggerHandler": "mouseenter", "triggerTarget": ".ld-pf-item", "triggerRelation": "closest", "offTriggerHandler": "mouseleave", "animationTarget": "this", "easing": "easeInOutCirc", "duration": 850, "offDuration": 700, "initValues": { "translateY": 0 }, "animations": { "translateY": -70 } }'>
                                                </div>
                                                <div className="ld-pf-details-inner" data-custom-animations="true"
                                                    data-ca-options='{ "triggerHandler": "mouseenter", "triggerTarget": ".ld-pf-item", "triggerRelation": "closest", "offTriggerHandler": "mouseleave", "animationTarget": ".split-inner", "easing": "easeOutQuint", "duration": 850, "offDuration": 750, "startDelay": 280, "offDelay": 280, "delay": 60, "initValues": { "translateY": 0 }, "animations": { "translateY": -70 } }'>
                                                    <h3 className="ld-pf-title h4" data-split-text="true"
                                                        data-split-options='{ "type": "lines" }'>iCompaas</h3>
                                                    <p data-split-text="true"
                                                        data-split-options='{ "type": "lines" }'>HTML/CSS</p>
                                                </div>
                                            </div>
                                            <a href="https://www.icompaas.com" className="liquid-overlay-link" target="_blank"></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12 masonry-item ecommerce grid-hover-overlay">
                                    <article
                                        className="ld-pf-item ld-pf-dark pf-bg-shadow pf-details-visible pf-details-boxed pf-details-pull-right pf-details-pull-up-half title-size-24 pf-details-h-str pf-details-v-end post-2021 liquid-portfolio type-liquid-portfolio status-publish format-gallery has-post-thumbnail hentry post_format-post-format-gallery liquid-portfolio-category-branding liquid-portfolio-category-digital-design liquid-portfolio-category-grid-hover-overlay">
                                        <div className="ld-pf-inner">
                                            <div className="ld-pf-image">
                                                <figure data-responsive-bg="true" data-parallax="true"
                                                    data-parallax-options='{ "parallaxBG": true }'>
                                                    <img src={require('../assets/demo/portfolio/Pulse-Agent.png')}></img>
                                                </figure>
                                            </div>
                                            <div className="ld-pf-details py-3">
                                                <div className="ld-pf-bg" style={{ backgroundColor: '#fff' }}
                                                    data-custom-animations="true"
                                                    data-ca-options='{ "triggerHandler": "mouseenter", "triggerTarget": ".ld-pf-item", "triggerRelation": "closest", "offTriggerHandler": "mouseleave", "animationTarget": "this", "easing": "easeInOutCirc", "duration": 850, "offDuration": 700, "initValues": { "translateY": 0 }, "animations": { "translateY": -70 } }'>
                                                </div>
                                                <div className="ld-pf-details-inner" data-custom-animations="true"
                                                    data-ca-options='{ "triggerHandler": "mouseenter", "triggerTarget": ".ld-pf-item", "triggerRelation": "closest", "offTriggerHandler": "mouseleave", "animationTarget": ".split-inner", "easing": "easeOutQuint", "duration": 850, "offDuration": 750, "startDelay": 280, "offDelay": 280, "delay": 60, "initValues": { "translateY": 0 }, "animations": { "translateY": -70 } }'>
                                                    <h3 className="ld-pf-title h4" data-split-text="true"
                                                        data-split-options='{ "type": "lines" }'>Pulse Agent
                                                        </h3>
                                                    <p data-split-text="true"
                                                        data-split-options='{ "type": "lines" }'>HTML/CSS
                                                        </p>
                                                </div>
                                            </div>
                                            <a href="https://pulseagent.co" className="liquid-overlay-link" target="_blank"></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12 masonry-item ecommerce grid-hover-overlay">
                                    <article
                                        className="ld-pf-item ld-pf-dark pf-bg-shadow pf-details-visible pf-details-boxed pf-details-pull-right pf-details-pull-up-half title-size-24 pf-details-h-str pf-details-v-end post-2021 liquid-portfolio type-liquid-portfolio status-publish format-gallery has-post-thumbnail hentry post_format-post-format-gallery liquid-portfolio-category-branding liquid-portfolio-category-digital-design liquid-portfolio-category-grid-hover-overlay">
                                        <div className="ld-pf-inner">
                                            <div className="ld-pf-image">
                                                <figure data-responsive-bg="true" data-parallax="true"
                                                    data-parallax-options='{ "parallaxBG": true }'>
                                                    <img src={require('../assets/demo/portfolio/Blue-Force-Marketing.png')}></img>
                                                </figure>
                                            </div>
                                            <div className="ld-pf-details py-3">
                                                <div className="ld-pf-bg" style={{ backgroundColor: '#fff' }}
                                                    data-custom-animations="true"
                                                    data-ca-options='{ "triggerHandler": "mouseenter", "triggerTarget": ".ld-pf-item", "triggerRelation": "closest", "offTriggerHandler": "mouseleave", "animationTarget": "this", "easing": "easeInOutCirc", "duration": 850, "offDuration": 700, "initValues": { "translateY": 0 }, "animations": { "translateY": -70 } }'>
                                                </div>
                                                <div className="ld-pf-details-inner" data-custom-animations="true"
                                                    data-ca-options='{ "triggerHandler": "mouseenter", "triggerTarget": ".ld-pf-item", "triggerRelation": "closest", "offTriggerHandler": "mouseleave", "animationTarget": ".split-inner", "easing": "easeOutQuint", "duration": 850, "offDuration": 750, "startDelay": 280, "offDelay": 280, "delay": 60, "initValues": { "translateY": 0 }, "animations": { "translateY": -70 } }'>
                                                    <h3 className="ld-pf-title h4" data-split-text="true"
                                                        data-split-options='{ "type": "lines" }'>Blue Force Marketing
                                                        </h3>
                                                    <p data-split-text="true"
                                                        data-split-options='{ "type": "lines" }'>WordPress
                                                        </p>
                                                </div>
                                            </div>
                                            <a href="https://blueforcemarketing.com" className="liquid-overlay-link" target="_blank"></a>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        </Section>
    );
}

export default OurPortfolio;
